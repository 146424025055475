import React from 'react'
import Layout from '../components/App/Layout'
import Navbar from '../components/Common/Navbar'
import PageBanner from '../components/Common/PageBanner'
import Footer from '../components/Common/Footer'
import ReactMarkdown from 'react-markdown'
import { graphql, useStaticQuery } from 'gatsby'
import NeedHelp from '../components/NeedHelp/NeedHelp'

export const query = graphql`
  {
    strapiPrivacyPolicy {
      effectiveDate: published_at(formatString: "MMMM D, YYYY")
      content
    }
  }
`

const PrivacyPolicy = () => {
  const data = useStaticQuery(query)
  const {
    strapiPrivacyPolicy: { effectiveDate: date, content },
  } = data

  return (
    <Layout>
      <Navbar />

      <PageBanner
        pageTitle="Privacy Policy"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Privacy Policy"
      />

      <section className="privacy-policy-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="privacy-policy-content">
                <p>
                  <i>This Privacy Policy was last updated on {date}.</i>
                </p>
                <ReactMarkdown source={content} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <NeedHelp />

      <Footer />
    </Layout>
  )
}

export default PrivacyPolicy
